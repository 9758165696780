$primary-color: #282a35;
$secondary-color: #253137;
$highlight-color: #04a76b;

.chevronUp {
  transform: rotate(180deg);
}

.planSelectionContainer {
  display: flex;
  flex-direction: column;
  background: $primary-color;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  background-image: url('path.svg'), url('bg.svg');
  background-repeat: no-repeat, no-repeat;
  background-position:
    top center,
    top center;
  background-size:
    70% auto,
    50% auto;
  height: 100%;
  color: white;
  font-family: "Source Sans Pro", sans-serif;
}

.headerLogo {
  position: absolute;
  left: 30px;
  top: 30px;
}

.chooseYourPlan {
  padding-top: 30px;
  text-align: center;

  h2,
  h5 {
    color: white;
    margin-top: 10px;
    margin-bottom: 20px;
    line-height: 1.5;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9em;
    text-align: center;
    max-width: 60%;
    margin: auto;
    margin-bottom: 5px;
  }

  .toggleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: -10px;

    .labels {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        color: white;
        font-size: 0.9em;
      }
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 17px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;
        border-radius: 34px;

        &:before {
          position: absolute;
          content: '';
          height: 13px;
          width: 13px;
          left: 2px;
          bottom: 2px;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }
      }

      input:checked + .slider {
        background-color: #04a76b;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #04a76b;
      }

      input:checked + .slider:before {
        transform: translateX(13px);
      }
    }
  }
}

.cardsWrapper {
  width: 100%;
  display: block;
}

.cardsContainer {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 20px 0;
  justify-content: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.cardsContainer::-webkit-scrollbar {
  display: none;
}

.cardsRow {
  margin-top: 30px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.cardColumn {
  flex: 0 0 340px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  min-width: 340px;
  max-width: 340px;
  margin-right:25px;
}

.cardContainer {
  background: radial-gradient(
    circle at 58.04px 56.96px,
    rgba(55, 166, 106, 0.33) 0%,
    rgba(57, 107, 154, 0.05) 100%
  );
  box-shadow:
    3px 3px 5px rgba(0, 0, 0, 0.3),
    inset 0 0 10px rgba(0, 0, 0, 0.2);

  color: white;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  transition: all 0.3s ease-in-out;
  border: 1px solid #53555d;
  width: 340px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  &:hover {
    @media (min-width: 599px) {
      transform: scale(1.04);
    }
    cursor: pointer;
    z-index: 1;
  }

  &.selected {
    border: 1px solid $highlight-color;
    @media (min-width: 599px) {
      transform: scale(1.09);
    }
    margin-top: 0px;
    margin-bottom: 0px;
    z-index: 2;
  }
}

.cardContent {
  flex: 1;
}

.noScaleNoPointer {
  &:hover {
    transform: scale(1);
    cursor: default;
    z-index: 1;
  }
}

// Ribbon
.popularRibbon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #04a76b;
  color: white;
  padding: 8px 20px;
  transform: rotate(45deg) translate(50%, 120%);
  transform-origin: top right;
  font-size: 0.9em;
  font-weight: 700;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  z-index: 10;
  white-space: nowrap;
  width: 200px;
}

// Card Details
.cardHeader {
  text-align: center;
  margin-bottom: 15px;
}

.cardTitle {
  font-size: 1.5em;
  margin-bottom: 5px;
  color: #04aa6d;
  text-align: left;
}

.cardPrice {
  font-size: 1.5em;
  font-weight: bold;

  .cardPeriod {
    font-size: 0.5em;
    color: #9aa1b7;
    font-weight: normal;
  }

  .classroomLink {
    font-size: 0.7em;
    color: white;
    font-weight: normal;
    text-decoration: underline;
  }

  text-align: left;
}

.cardDescription {
  font-size: 15px;
  margin-bottom: 15px;
  text-align: left;
}

.featuresList {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  text-align: left;
  font-size: 14px;

  .featureItem {
    margin-bottom: 5px;
    position: relative;
    margin-right: 20px;

    &::before {
      content: '\2713';
      color: white;
      font-size: 10px;
      line-height: 16px;
      background-color: #04aa6d;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      margin-right: 5px;
    }
  }

  .specialFeature::before {
    content: '❤';
    color: #04aa6d;
    margin-right: 5px;
  }
}

// Buttons
.outlineBtn {
  margin-top: auto;
  border: 1px solid #d3d4d7;
  color: white;
  background-color: transparent;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}

.cardContainer .outlineBtn {
  align-self: center;
  min-width: 100px;
}

.actionButtons {
  margin-top: 20px;
  button {
    margin: 10px;
    padding: 10px 20px;
    border: 1px solid #d3d4d7;
    color: white;
    min-width: 150px;

    &:hover {
      border: 1px solid #ffffff;
      color: #ffffff;
    }

    &:last-child {
      background-color: #04aa6d;
      border: none;
    }
  }

  .outlineBtn {
    padding: 10px 20px;
    min-width: 150px;
  }
}

//Plan checkbox
.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: absolute;
  right: 10px;
  width: 25px;
  height: 25px;
  background-color: #fafafa;
  border: 2px solid #d1d3d4;
  border-radius: 50%;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 10px;

  &:checked {
    background-color: #04aa6d;
    border: 2px solid #04aa6d;

    &:after {
      content: '\2713';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &:not(:checked) {
    border: 2px solid white;
  }
}

// Classroom
.classroomRow {
  justify-content: center;
  margin-top: 20px;
}

.classroomSection {
  background: linear-gradient(
    to right,
    rgba(55, 166, 106, 0.28) 0%,
    rgba(57, 107, 154, 0.05) 100%
  );
  border: 1px solid #53555d;
  border-radius: 15px;
  box-shadow:
    3px 3px 5px rgba(0, 0, 0, 0.3),
    /* Drop shadow */ inset 0 0 10px rgba(0, 0, 0, 0.2); /* Inner shadow */
  color: white;
  padding: 20px;
  margin: 50px 10px 5px 10px;
}

.classroomText {
  color: #04a76b;
  text-align: left;
  font-weight: 700;
}

.priceUponRequest {
  font-weight: 700;
  text-align: left;
}

@media (max-width: 955px) {
  .cardsContainer {
    justify-content: flex-start;
    padding: 0px 0px 12px 10px;
    scrollbar-width: thin;
    -ms-overflow-style: auto;
  }

  .cardContainer {
    padding: 10px;
  }

  .cardsContainer::-webkit-scrollbar {
    display: block;
  }
  .cardColumn {
    min-width: 340px;
    margin: 10px 5px 5px 5px;
  }

  .cardHeader {
    text-align: center;
    margin-bottom: 0px;
  }

  .cardTitle {
    font-size: 1em;
  }

  .cardPrice {
    font-size: 0.9em;

    .cardPeriod {
      font-size: 0.8em;
    }
  }

  .featuresList {
    list-style: none;
    padding: 0;
    margin-bottom: 1px;
    text-align: left;
    font-size: 12px;

    .featureItem {
      margin-bottom: 4px;
      position: relative;
      margin-right: 0px;

      &::before {
        content: '\2713';
        color: white;
        font-size: 8px;
        line-height: 16px;
        background-color: #04aa6d;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        margin-right: 5px;
      }
    }

    .specialFeature::before {
      content: '❤';
      color: #04aa6d;
      margin-right: 5px;
      font-size: 16px;
      line-height: 16px;
    }

    .specialFeature {
      margin-bottom: 20px;
    }
  }

  .popularRibbon {
    transform: rotate(45deg) translate(50%, 85%);
    width: 115px;
    padding: 4px 10px;
  }

  .cardDescription {
    font-size: 12px;
  }

  .headerLogo {
    position: static;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 20;
    h2 {
      font-size: 1.1em;
      line-height: 1.2em;
      margin-bottom: 5px;
    }
  }

  .chooseYourPlan {
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 5px;

    h2 {
      font-size: 0.9em;
      line-height: 1em;
      margin-bottom: 5px;
    }

    p {
      font-size: 0.8em;
      padding: 5px;
      max-width: 90%;
      margin-bottom: 5px;
    }

    .toggleWrapper {
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      //margin-bottom: 5px;
    }
  }

  .classroomRow {
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .classroomSection {
    text-align: center;
    margin-top: 10px;
    padding: 20px 5px 5px 20px;
    width: auto;
  }

  .learningSolution {
    text-align: left;
  }

  .classroomText {
    font-size: 1.2em;
    margin-bottom: 20px;
  }

  .priceUponRequest,
  .classroomSection div:not(.btn) {
    font-size: 0.9em;
    margin-bottom: 15px;
    width: 100%;
  }

  .actionButtons {
    margin-top: 0px;
  }

  html,
  body {
    overflow-x: hidden; // to avoid white space issue
  }
}

@media (max-width: 942px) {
  .cardsContainer {
    justify-content: flex-start;
    padding: 0px 0px 20px 10px;
  }

  .headerLogo {
    top: 5%;
  }
}

@media (max-width: 500px) {
  .chooseYourPlan {
    padding-top: 0px;

    h1 {
      font-size: 1.5em;
      margin-top: 20px;
      margin-bottom: 0px;
    }
    h2,
    h5 {
      font-size: 1.3em;
      margin-top: 10px;
      margin-bottom: 0px;
    }

    p {
      padding: 0px;
      margin-bottom: 0px;
    }

    .toggleWrapper {
      margin-top: 30px;
      margin-bottom: -35px;
    }
  }
  .cardsContainer {
    justify-content: flex-start;
    overflow-x: hidden;
    width: 100%;
    padding-right: 20px;

    &:hover {
      transform: scale(1);
      cursor: pointer;
      z-index: 1;
    }

    &.selected {
      border: 1px solid $highlight-color;
      transform: scale(1) !important;
      margin-top: 0px;
      margin-bottom: 0px;
      z-index: 2;
    }
  }

  .cardContainer {
    width: 100%;
  }

  .cardsRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cardColumn {
    width: 100%;
    padding: 0px 20px;
    max-width: 100%;
  }
}

@media (max-width: 900px) {
  .cardContainer {
    width: 100%;
  }

  .cardContent {
    width: 100%;
  }

  .cardColumn {
    flex: 0 0 calc(20% - 40px);
    min-width: 280px;
    max-width: calc(20% - 40px);
  }
}

@media (min-height: 780px) and (min-width: 950px) {
  .chooseYourPlan {
    padding-top: 70px;

    h2,
    h5 {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      font-size: 0.9em;
      text-align: center;
      max-width: 60%;
      margin: auto;
      margin-bottom: 5px;
    }

    .toggleWrapper {
      margin-bottom: 10px;
    }
  }

 
}

.tooltip-arrow {
  top:50% !important;
}

.selectButton {
  min-width: 100px;
  width: auto;
  padding: 4px 8px;
  margin-top: auto;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: center;

  &:hover:not(:disabled) {
    background: rgba(255, 255, 255, 0.1);
    border-color: white;
    color: white;
  }

  &.selected {
    border-color: white;
    color: white;
    cursor: not-allowed;
  }
}